<template>
  <div class="accepted-sign">
    <img src="@/assets/img/accept-signature.svg" alt="">
    <h5>{{ $t('signature.accepted.title') }}</h5>
    <p>{{ $t('signature.accepted.description') }}</p>
  </div>
</template>

<script>
export default {
  name: 'sign-accepted',
}
</script>

<style lang="scss" scoped>
.accepted-sign {
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    width: 150px;
    margin-bottom: 25px;
  }

  p {
    font-family: Inter, sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    text-align: center;
    color: #000000;
  }

  h5 {
    font-family: Inter, sans-serif;
    font-weight: 700;
    font-size: 35px;
    line-height: 120%;
    text-transform: capitalize;
    color: #21979E;
    margin-bottom: 10px;
  }
}
</style>
