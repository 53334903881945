export default {
  'driver-out': 'Driver Out',
  'data-unavailable': 'Data unavailable',
  delivered: 'Delivered',
  'wrong-address': 'Wrong address',
  'not-present': 'Not present',
  'order-canceled': 'Order Canceled',
  'delivery-attempted': 'Delivery Attempted',
  rescheduled: 'Rescheduled',
  'date-unavailable': 'Date unavailable',
}
