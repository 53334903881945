export default {
  delivered: {
    'link-feedback-popup': {
      title: 'Thank you for using our service and we appreciate your feedback.',
      subtitle: 'Please leave a review on our Google page',
      btn: 'Ok',
    },
    info: {
      'payment-delivered-title': 'Delivered',
    },
    feedback: {
      title: 'Your feedback matters to us',
      comment: {
        title: 'Please leave your comments:',
        placeholder: 'Write down if you liked everything, your expectations and impressions',
        'send-btn': 'Send feedback',
        'change-btn': 'Change feedback',
        'not-send-tooltip': {
          text: 'Contact me',
          title: 'Customer service will call you',
          content: 'Please leave your feedback, and our Customer Service Agent will contact you shortly.',
        },
      },
      suggestions: {
        driver: {
          positive: {
            'went-above-and-beyond': 'Went Above and Beyond',
            'professional-appearance': 'Professional Appearance',
            'courteous-and-respectful': 'Courteous and Respectful',
            'on-time-delivery': 'On time delivery',
          },
          negative: {
            'unfriendly-driver': 'Unfriendly Driver',
            'did-not-follow-instructions': 'Did Not Follow Instructions',
            'late-delivery': 'Late Delivery',
            'unprofessional-appearance': 'Unprofessional Appearance',
          },
        },
        dispatcher: {
          positive: {
            'great-communication': 'Great Communication',
            'patient-and-understanding': 'Patient and Understanding',
            'efficient-coordination': 'Efficient Coordination',
            'quick-response-time': 'Quick Response Time',
          },
          negative: {
            'impatient-or-rude': 'Impatient or Rude',
            'poor-coordination': 'Poor Coordination',
            'failed-to-address-concerns': 'Failed to Address Concerns',
            'slow-response-time': 'Slow Response Time',
          },
        },
        pharmacy: {
          positive: {
            'friendly-staff': 'Friendly Staff',
            'secure-packaging': 'Secure Packaging',
            'fast-shipping': 'Fast Shipping/Refills',
            'concise-medication-instructions': 'Concise Medication Instructions',
          },
          negative: {
            'difficult-to-open': 'Difficult to open',
            'incorrect-prescription': 'Incorrect Prescription',
            'unfriendly-staff': 'Unfriendly Staff',
            'wrong-medication': 'Wrong medication',
          },
        },
      },
    },
  },
  delivering: {
    confirmed: {
      'stops-away': 'stops away',
      'your-stop-is-next': 'Your stop is next',
      'special-instruction': {
        title: 'Pharmacy Special Instruction:',
        'new-instruction-input': {
          title: 'Recipient’s Special Instructions',
          placeholder: 'Add special instruction',
        },
        'new-instruction-btn-save': 'Save',
        'not-editable-warning': 'Sorry, you cannot change the special instruction because the driver is already nearby.',
      },
      'arriving-data': {
        today: {
          'label-2': 'Arriving today',
          label: 'Estimated delivery time',
        },
        next: {
          label: 'The driver will arrive in',
          time: 'Few minutes',
        },
        'few-minutes': {
          'label-2': 'The driver will arrive in',
        },
      },
      qr: {
        info: 'Show the QR code to the driver',
        description: 'To receive your order, show the QR code to the driver or call the code',
        help: 'A copay is a specific dollar amount that you’re required to pay for covered health care services or prescriptions, as defined by your insurance plan.',
      },
    },
    'confirmed-no-route': {
      title: 'You confirmed delivery',
      subtitle: 'Stand by for information',
    },
    'not-confirmed': {
      form: {
        'empty-title': 'Be sure to leave a signature',
        'pharmacy-instruction-title': 'Pharmacy Special Instruction:',
        'recipient-instruction': {
          title: 'Recipient’s Special Instructions:',
          placeholder: 'Add special instruction',
          'update-btn': 'Update',
        },
        sign: {
          description: 'Driver will attempt face-to-face delivery. If nobody is home, driver will leave the package by the door and take a picture.',
          confirm: {
            btn: 'Confirm delivery',
            title: 'Are you sure?',
            text: 'I confirm that information I have provided is correct and accurate',
          },
        },
      },
      progress: {
        attempts: 'Delivery Attempt:',
        created: 'Order <br> created',
      },
      transit: 'Transit',
      'out-for-delivery': 'Out for <br> Delivery',
      delivered: 'Delivered',
      signature: {
        title: 'Sign below to allow package drop-off without signature:',
        clear: 'Clear',
      },
      statuses: {
        'at-pharmacy': 'Your order is at the pharmacy and will be delivered',
        'driver-out': 'Confirm your Delivery',
        rescheduled: 'Delivery rescheduled for',
        'pending-back-to-pharmacy': 'Your order is pending return back to the pharmacy',
        'pickup-occured': 'Your order has been picked up from the pharmacy and will be delivered',
      },
    },
    rescheduled: {
      attempted: 'Attempted on',
    },
  },
  investigation: {
    link_start: 'Please contact us at',
    link_end: 'to schedule a delivery',
    instructions: 'Pharmacy Special Instruction:',
  },
  rescadule: {
    subtitle: 'Please, select a new delivery date',
  },
  rejected: {
    'back-to-pharmacy': {
      rejected: 'Order has been canceled by pharmacy',
      'pending-back-to-pharmacy': 'Your order has been canceled and pending return back to the pharmacy',
      'on-its-way-to-pharmacy': 'Your order has been canceled and confirmed return back to the pharmacy',
      default: 'Your order has been cancelled and sent back to the pharmacy',
      'confirmed-back-to-pharmacy': 'Your order has been canceled, awaiting pharmacy return confirmation',
      'back-to-pharmacy': 'Your order has been cancelled and sent back to the pharmacy',
    },
  },
  returned: {
    'back-to-pharmacy': {
      title: 'Your order is pending return back to the pharmacy',
    },
  },
}
