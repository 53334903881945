<template>
  <section v-if="routeData" class="section map_route">
    <div class="section__content map_route__content">
      <map-component></map-component>
      <div v-if="arrivingData" class="map_route__standby" style="z-index: 3">
        <div class="icon map_route__standby__icon">
          <div class="bg" style="padding: 5px;">
            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M9.738 19.245c0 1.322-1.178 2.393-2.632 2.393-1.453 0-2.63-1.071-2.63-2.393 0-1.321 1.177-2.393 2.63-2.393 1.454 0 2.632 1.072 2.632 2.393zM25.525 19.245c0 1.322-1.178 2.393-2.631 2.393-1.454 0-2.632-1.071-2.632-2.393 0-1.321 1.178-2.393 2.632-2.393 1.453 0 2.63 1.072 2.63 2.393z" fill="#005E9A"/><path fill-rule="evenodd" clip-rule="evenodd" d="M12.04 13.264v-3.59h4.506c.362 0 .69.12.92.36l3.553 3.23h-8.98zm-1.316 0H2.403l3.552-3.23c.263-.24.592-.36.921-.36h3.848v3.59zm15.459 0h-2.73c-.362 0-.69-.12-.921-.359l-4.144-3.739c-.494-.448-1.152-.687-1.875-.687H6.876c-.69 0-1.381.239-1.875.687L.923 12.905c-.263.24-.395.538-.395.867v3.081c0 1.316 1.184 2.393 2.631 2.393h.33c0-1.825 1.611-3.29 3.617-3.29 2.007 0 3.618 1.465 3.618 3.29h8.552c0-1.825 1.611-3.29 3.618-3.29 2.006 0 3.618 1.465 3.618 3.29h1.644c.724 0 1.316-.539 1.316-1.197v-1.794c0-1.645-1.48-2.991-3.29-2.991z" fill="#005E9A"/></svg>
          </div>
        </div>
        <div class="standby-col">
          <div v-if="secondLineInTimeBlock" class="standby-line" style="margin-bottom: 5px">
            <h5 class="map_route__standby__title">{{ arrivingData.label2 }}</h5>
            <p class="map_route__standby__time">{{ arrivingData.time2 }}</p>
          </div>
          <div class="standby-line">
            <h5 class="map_route__standby__title">{{ arrivingData.label }}:</h5>
            <div>
              <p class="map_route__standby__time">{{ arrivingData.time }}</p>
              <template v-if="arrivingData.stops">
                <p v-if="arrivingData.stops > 0" class="map_route__standby__stops">
                  {{ arrivingData.stops }} {{ $t('main-page.delivering.confirmed.stops-away') }}
                </p>
                <p v-else class="map_route__standby__stops">{{ $t('main-page.delivering.confirmed.your-stop-is-next') }}</p>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="section " :class="{'route_info': routeData}">
    <div class="section__content">
      <div class="driver-qr-block">
        <staff-block-qr
          v-if="orderData.driver"
          :name="orderData.driver.name"
          :rate="orderData.driver.rate"
          :avatar="orderData.driver.avatar"
        ></staff-block-qr>
        <qr-block-small v-if="orderData.qr_approve"></qr-block-small>
      </div>

      <div v-if="allowTips" class="tips-section-no-border" style="padding-top: 0; padding-bottom: 0; margin-bottom: 0;">
        <div class="tips-border-style">
          <tips-component></tips-component>
        </div>
      </div>

      <div class="px-8" style="padding-top: 5px">
        <div class="client_info__container">
          <delivery-to :long="true"></delivery-to>
          <delivery-from
            v-if="orderData.client"
            :logo="orderData.client?.avatar"
          ></delivery-from>
        </div>

        <div v-if="orderData.instructions.pharmacy" style="width: 100%; margin-top: 20px;">
          <h5 class="info_title form__input__title">{{ $t('main-page.delivering.confirmed.special-instruction.title') }}</h5>
          <p style="margin-bottom: 10px; font-size: 14px; color: #757575">{{ orderData.instructions.pharmacy }}</p>
        </div>

        <div v-if="routeData && routeData?.stopsAway < 1" class="special-instructions-panel">
          <h5 class="info_title form__input__title">{{ $t('main-page.delivering.confirmed.special-instruction.new-instruction-input.title') }}</h5>
          <div>{{ newSpecialInstruction }}</div>
          <div class="not-editable-warning">
            {{ $t('main-page.delivering.confirmed.special-instruction.not-editable-warning') }}
          </div>
        </div>
        <div v-else style="margin-top: 20px;">
          <t-input
            v-model="newSpecialInstruction"
            :title="$t('main-page.delivering.confirmed.special-instruction.new-instruction-input.title')"
            :placeholder="$t('main-page.delivering.confirmed.special-instruction.new-instruction-input.placeholder')"
          ></t-input>
          <t-button v-if="!cantUpdateSpIn" :class="'success'" @click="updateSpecialInstruction">{{ $t('main-page.delivering.confirmed.special-instruction.new-instruction-btn-save') }}</t-button>
        </div>

        <div style="margin-top: 10px">
          <approve-type></approve-type>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import { useToast } from 'vue-toastification'

import TButton from '@/components/UI/t-button.vue'
import TInput from '@/components/UI/form/t-input.vue'

import { getOrderInfo, updateSpecialInstruction } from '@/services/orderService'

import DeliveryTo from '@/components/_common/delivery-to.vue'
import DeliveryFrom from '@/components/_common/delivery-from.vue'

import ApproveType from '@/components/_common/ApproveType/index.vue'

import { convertTime12to24, estimatedMinutesToTime } from '@/helpers'
import TipsComponent from '@/components/_common/TipsComponents/tips-component.vue'
import StaffBlockQr from './components/staff-block-qr.vue'
import QrBlockSmall from './components/qr-block-small.vue'

import MapComponent from './map-component-leaflet.vue'

export default {
  name: 'confirmed-view',
  components: {
    TipsComponent,
    TButton,
    MapComponent,
    QrBlockSmall,
    StaffBlockQr,
    TInput,
    ApproveType,
    DeliveryFrom,
    DeliveryTo,
  },
  data() {
    return {
      newSpecialInstruction: '',
    }
  },
  computed: {
    ...mapGetters(['orderData', 'routeData', 'allowTips']),
    arrivingData() {
      const estimatedMinutes = this.orderData.eta.estimatedTime
      const { arrivalTime } = this.orderData.eta
      const { stopsAway } = this.routeData

      const { from } = this.orderData.eta
      const { to } = this.orderData.eta

      if (estimatedMinutes && stopsAway && arrivalTime) {
        if (stopsAway > 5) {
          return {
            label2: this.$t('main-page.delivering.confirmed.arriving-data.today.label-2'),
            time2: `${convertTime12to24(from)} - ${convertTime12to24(to)}`,
            stops: null,
            label: this.$t('main-page.delivering.confirmed.arriving-data.today.label'),
            time: `~${arrivalTime}`,
          }
        }

        if (estimatedMinutes >= 60) {
          return {
            label2: this.$t('main-page.delivering.confirmed.arriving-data.today.label-2'),
            time2: `${convertTime12to24(from)} - ${convertTime12to24(to)}`,
            stops: null,
            label: this.$t('main-page.delivering.confirmed.arriving-data.today.label'),
            time: `~${arrivalTime}`,
          }
        }

        if (estimatedMinutes < 1 && stopsAway < 1) {
          return {
            label: this.$t('main-page.delivering.confirmed.arriving-data.next.label'),
            time: this.$t('main-page.delivering.confirmed.arriving-data.next.time'),
            stops: this.$t('main-page.delivering.confirmed.your-stop-is-next'),
          }
        }

        return {
          label2: this.$t('main-page.delivering.confirmed.arriving-data.few-minutes.label-2'),
          time2: `~${estimatedMinutes}min`,
          stops: null,
          label: this.$t('main-page.delivering.confirmed.arriving-data.today.label'),
          time: `~${arrivalTime}`,
        }
      }

      return {
        label2: this.$t('main-page.delivering.confirmed.arriving-data.today.label-2'),
        time2: `${convertTime12to24(from)} - ${convertTime12to24(to)}`,
        stops: null,
        label: this.$t('main-page.delivering.confirmed.arriving-data.today.label'),
        time: `~${arrivalTime}`,
      }
    },
    secondLineInTimeBlock() {
      return this.arrivingData.time2 && this.arrivingData.label2
    },
    cantUpdateSpIn() {
      return (this.newSpecialInstruction === this.orderData.instructions.recipient)
        || (this.newSpecialInstruction === '' && this.orderData.instructions.recipient === null)
        || (this.newSpecialInstruction === null && this.orderData.instructions.recipient === '')
    },
  },
  async mounted() {
    this.newSpecialInstruction = this.orderData.instructions.recipient
  },
  methods: {
    async updateSpecialInstruction() {
      const toast = useToast()
      const response = await updateSpecialInstruction(this.orderData.uid, { recipient_instruction: this.newSpecialInstruction })
      if (response.success) {
        toast.success(response.success)
        const { order } = await getOrderInfo(this.orderData.uid)
        this.$store.commit('setOrderData', order)
      } else {
        window.location.reload()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.special-instructions-panel {
  margin: 16px 0;

  .not-editable-warning {
    color: #ffffff;
    background-color: #ff3c5f;
    border-radius: 12px;
    font-size: 16px;
    font-weight: 700;
    padding: 10px;
    margin: 10px 0;
  }
}
</style>
