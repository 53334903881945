<template>
  <div class="wrapper-section">
    <section class="section">
      <div class="section__content">
        <signature-pad v-if="!orderData.signature"></signature-pad>
        <sign-accepted v-else></sign-accepted>
      </div>
    </section>
  </div>
</template>

<script>
import SignaturePad from '@/components/SignatureLink/signature-pad.vue'
import SignAccepted from '@/components/SignatureLink/sign-accepted.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'signature-link',
  components: {
    SignaturePad,
    SignAccepted,
  },
  computed: {
    ...mapGetters(['orderData']),
  },
}
</script>
